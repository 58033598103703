// Generated by Framer (b75150a)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Yzm_zCzGk"];

const serializationHash = "framer-MWwvC"

const variantClassNames = {Yzm_zCzGk: "framer-v-1dj04fk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Yzm_zCzGk", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1dj04fk", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Yzm_zCzGk"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{background: "linear-gradient(270deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0) 100%)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, ...style}}><motion.div className={"framer-wp6whi"} layoutDependency={layoutDependency} layoutId={"BTDtW_auQ"} style={{backgroundColor: "rgba(255, 255, 255, 0.2)", borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: 1, borderTopRightRadius: 1, boxShadow: "0px 0px 6px 1px rgba(255, 255, 255, 0.6)"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MWwvC.framer-1c6d28, .framer-MWwvC .framer-1c6d28 { display: block; }", ".framer-MWwvC.framer-1dj04fk { height: 1px; overflow: visible; position: relative; width: 100px; }", ".framer-MWwvC .framer-wp6whi { bottom: 0px; flex: none; overflow: visible; position: absolute; right: 0px; top: 0px; width: 2px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerENjrzV_s3: React.ComponentType<Props> = withCSS(Component, css, "framer-MWwvC") as typeof Component;
export default FramerENjrzV_s3;

FramerENjrzV_s3.displayName = "Elements/Shimmer";

FramerENjrzV_s3.defaultProps = {height: 1, width: 100};

addFonts(FramerENjrzV_s3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})